import PropTypes from 'prop-types';
import React from 'react';
import styles from './style.module.css';
import Text from '../../../components/shared/Text';

const MobileSignedOutHeader = ({ children }) => {
  return (
    <div>
      <div className={styles.bg}>
        <Text size="s19" color="#F1C400" lineHeight="h35" weight="wb">
          RoofClaim.com
        </Text>
      </div>
      {children}
    </div>
  );
};

export default MobileSignedOutHeader;

MobileSignedOutHeader.propTypes = {
  children: PropTypes.node,
};

MobileSignedOutHeader.defaultProps = {
  children: null,
};
