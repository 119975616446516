import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import AppConfigContext from 'context/AppConfigContext';
import WrapperBlock from 'components/shared/WrapperBlock';
import { Footer as Buttons, CenterButton } from 'components/Projects/RoofProjects/Footer';
import Text from 'components/shared/Text';
import pluralNoun from 'utils/pluralNoun';
import NoteText from 'components/shared/NoteText';
import Frame from 'components/shared/Frame';
import styles from '../../style.module.css';

const TitleBalance = ({ name, value }) => {
  if (!value) return null;
  return (
    <div>
      <div>
        <Text color="#686868" size="s14" lineHeight="h29">
          {name}
        </Text>
      </div>

      <Text color="#000" weight="wb" size="s14">
        {value}
      </Text>
    </div>
  );
};

TitleBalance.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
};

TitleBalance.defaultProps = {
  name: '',
  value: '',
};

const BillingStep = ({
  handleClose,
  depositBalance = 0,
  balance = 0,
  overdueDays = 0,
  totalBalance = 0,
  isConfirmStage,
  projectId,
  handleNextStep,
}) => {
  const config = useContext(AppConfigContext);
  const [isOpenIframe, setIsOpenIframe] = useState(false);
  const paymentLink = `${config?.paymentUrl}prcs=${balance}&rtyp=Opportunity&id=${projectId}&bkurl=${window.location.href}&srvct=Balance`;

  function handleEvent(e) {
    if (e.details === 'refresh') {
      window.location.reload();
    }
  }
  window.document.addEventListener('refreshEvent', handleEvent, false);

  return (
    <>
      <div>
        <WrapperBlock title="Billing">
          <div className={styles.formContent}>
            <div className={styles.projectFormContent}>
              <div className={styles.rowContainerMargin}>
                <Text color="#686868" size="s24">
                  Invoice Total
                </Text>
              </div>

              <Text color="#000" weight="wb" size="s21">
                $ {new Intl.NumberFormat().format(totalBalance)}
              </Text>
            </div>

            <div className={[styles.projectFormContent, styles.gridSecond].join(' ')}>
              <div>
                <TitleBalance
                  name="Deposit Balance"
                  value={`$ ${new Intl.NumberFormat().format(depositBalance)}`}
                />
              </div>
              <div>
                <TitleBalance
                  name="Balance"
                  value={`$ ${new Intl.NumberFormat().format(balance)}`}
                />
              </div>
              {/* <div>
                <TitleBalance
                  name="Days Outstanding"
                  value={pluralNoun(overdueDays || 0, 'day')}
                />
              </div> */}
            </div>
          </div>

          <NoteText>
            RoofClaim.com typically sends all requisite documents to your insurance and/or
            mortgage company. However, the homeowner is ultimately responsible for helping
            to secure the payment of all amounts from the insurance and/or mortgage
            company. This responsibility includes being responsive to RoofClaim’s requests
            for signatures, endorsement or relinquishing of checks or reaching out to the
            insurance and/or mortgage company and updating RoofClaim.com on the status of
            communications
            <br />
            If you are in possession an insurance check, please contact us to further
            assist.
          </NoteText>

          {balance && !isOpenIframe ? (
            <CenterButton id="btn-pay-now" handleClick={() => setIsOpenIframe(true)}>Pay Now</CenterButton>
          ) : null}
          {isOpenIframe ? <Frame link={paymentLink} /> : null}
        </WrapperBlock>
      </div>
      <Buttons
        handleCancel={handleClose}
        handleNextStep={handleNextStep}
        isHideNext={!isConfirmStage}
        isHideSubmit
      />
    </>
  );
};

BillingStep.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleNextStep: PropTypes.func.isRequired,
  depositBalance: PropTypes.number,
  balance: PropTypes.number,
  overdueDays: PropTypes.number,
  totalBalance: PropTypes.number,
  isConfirmStage: PropTypes.bool,
  projectId: PropTypes.string.isRequired,
};

BillingStep.defaultProps = {
  overdueDays: 0,
  totalBalance: 0,
  balance: 0,
  depositBalance: 0,
  isConfirmStage: false,
};

export default BillingStep;
