import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import FollowAnalytics from 'followanalytics';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { FIRST_TIME_SIGN_IN_MUTATION } from 'graphql/mutations/auth';
import { GET_CURRENT_USER } from 'graphql/cache';
import MobileContext from 'context/MobileContext';
import StartRoofProjectModal from 'components/shared/modals/StartRoofProjectModal';
import useModal from 'services/hooks/useIsShowComponent';
import AcknowledgmentModal from 'components/shared/modals/AcknowledgmentModal';
import WelcomeModal from '../shared/modals/WelcomeModal';
import Ctab from './CtaBanner'
import Dron from './Dron';
import OurService from './OurService';
import ContactUsDesktop from './ContactUs/Desktop';
import ContactUsMobile from './ContactUs/Mobile';
// import RelatedArticles from './RelatedArticles';
import MyProject from './MyProject';
import styles from './styles.module.css';

const MainContent = ({ projects = [], history }) => {
  const {
    isShow: isShowRoofProjectModal,
    handleClick: handleClickRoofProjectModal,
  } = useModal();
  const { isShow: isOpenModal, handleClick: handleClickWarrantyModal } = useModal();

  const [isShowWelcomeModal, setIsShowWelcomeModal] = useState(false);
  const [firstTimeSignIn] = useMutation(FIRST_TIME_SIGN_IN_MUTATION, {
    update: (proxy) => {
      const { user } = proxy.readQuery({ query: GET_CURRENT_USER });
      user.isFirstLogin = false;
      proxy.writeQuery({ query: GET_CURRENT_USER, data: { user } });
    },
  });

  const { data } = useQuery(GET_CURRENT_USER);

  const isMobile = useContext(MobileContext);

  useEffect(() => {
    if (data?.user?.isFirstLogin) {
      setIsShowWelcomeModal(true);
      localStorage.setItem('isFirstLogin', 'true');
    } else {
      localStorage.setItem('isFirstLogin', 'false');
    }
  }, [data]);

  useEffect(() => {
    if (history.action === 'REPLACE' && history.location.state === '/warranty')
      handleClickWarrantyModal();
  }, []);

  useEffect(() => {
    FollowAnalytics.logEvent('Dashboard_Screen');
    FollowAnalytics.UserAttributes.setString('key_State', data?.user?.account.billingState);
    FollowAnalytics.UserAttributes.setString('key_ZIP', data?.user?.account.billingPostalCode);
  }, []);

  const uncheckFirstTimeSignIn = async () => {
    if (data?.user?.isFirstLogin)
      await firstTimeSignIn({
        variables: { id: data.user?.pgId },
      });
  };

  const handleClick = async () => {
    setIsShowWelcomeModal(false);
    await uncheckFirstTimeSignIn();
  };

  const handleCloseWelcomeModal = async () => {
    setIsShowWelcomeModal(false);
    await uncheckFirstTimeSignIn();
  };

  return (
    <div className={styles.container}>
      <WelcomeModal
        handleClose={handleCloseWelcomeModal}
        handleClick={handleClick}
        isShow={isShowWelcomeModal}
      />
      <AcknowledgmentModal
        title="Warranty"
        description="The Warranty section will become available after a Roof Installation has been completed."
        handleClick={handleClickWarrantyModal}
        handleClose={handleClickWarrantyModal}
        isShow={isOpenModal}
        buttonText="OK"
        isSmallSize
      />
      <StartRoofProjectModal
        handleClose={handleClickRoofProjectModal}
        isShow={isShowRoofProjectModal}
        handleClick={() => {
          handleClickRoofProjectModal();
          FollowAnalytics.logEvent('Start_New_Project_BTN');
          history.push('/my-projects/roof-project');
        }}
      />
      {/* {Call to Action Banner currently configured for LA Hurricane Ida} */}
      {/*<Ctab />*/}
      <Dron />
      {projects.length ? <MyProject projects={projects} history={history} /> : null}

      {!projects.length ? (
        <OurService
          isHaveProject={!!projects.length}
          handleCreateRoofProject={handleClickRoofProjectModal}
          history={history}
        />
      ) : null}

      {!isMobile && (
        <ContactUsDesktop
          titleText="Need help?"
          bodyText="If you have a question or issue navigating the website, please reach out to the
          RoofClaim.com support team via the options below."
        />
      )}
      {isMobile && <ContactUsMobile />}
    </div>
  );
};

MainContent.propTypes = {
  projects: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
};

export default MainContent;
