import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import Text from 'components/shared/Text';
import { CenterButton } from 'components/Projects/RoofProjects/Footer';
import NoteText from 'components/shared/NoteText';
import Frame from 'components/shared/Frame';
import AppConfigContext from 'context/AppConfigContext';
import styles from '../../../style.module.css';

function DefaultContentPay({ description, balance, opportunitySfId }) {
  const config = useContext(AppConfigContext);
  const [showButton, setShowButton] = useState(true);
  const paymentLink = `${config?.paymentUrl}prcs=${balance}&rtyp=Opportunity&id=${opportunitySfId}&bkurl=${window.location.href}&srvct=Deposit`;

  // function reload() {
  //   document.getElementById('iframeid').src += '';
  // } btn.onclick = reload;

  function handleEvent(e) {
    if (e.details === 'refresh') {
      window.location.reload();
    }
  }

  window.document.addEventListener('refreshEvent', handleEvent, false);

  return (
    <div>
      <div className={styles.wrapper}>
        <div className={styles.rowContainerMargin}>
          <Text color="#686868" size="s24" tag="p" lineHeight="h36">
            Deductible Amount
          </Text>
        </div>
        <Text color="#000000" size="s21" weight="wb">
          $ {Intl.NumberFormat().format(balance)}
        </Text>
      </div>

      <div className={styles.contentPayFooter}>
        <NoteText>{description}</NoteText>
      </div>

      {showButton && (
        <CenterButton handleClick={() => setShowButton(false)}>
          Pay Deductible
        </CenterButton>
      )}
      {!showButton ? <Frame link={paymentLink} /> : null}
    </div>
  );
}

DefaultContentPay.propTypes = {
  balance: PropTypes.number,
  description: PropTypes.string,
  opportunitySfId: PropTypes.string.isRequired,
};

DefaultContentPay.defaultProps = {
  balance: 1000,
  description: `RoofClaim.com gladly accepts payment in the form of good funds, 
  credit card, personal check or insurance/mortgage company check. 
  For your convenience, you may make an ACH payment via our portal or by calling ${process.env.REACT_APP_ROOFCALIM_PHONE} at no additional charge.`,
};

export default DefaultContentPay;
