import React, { useState } from 'react';
import PropTypes from 'prop-types';
import WrapperBlock from 'components/shared/WrapperBlock';
import Text from 'components/shared/Text';
import Icon from 'components/shared/IconSVG';
import { editIcon } from 'assets/icons';
import ViewMode from './ViewMode';
import EditMode from './EditMode';

import styles from './styles.module.css';

const Contacts = ({ user }) => {
  const [isEditMode, setIsEditMode] = useState(false);

  return (
    <WrapperBlock
      title={user?.account?.contacts?.length > 0 ? 'Contacts' : 'Contact'}
      isForm={isEditMode}
    >
      {!isEditMode && (
        <div className={styles.editContainer} onClick={() => setIsEditMode(true)}>
          <Icon src={editIcon} height="12px" width="12px" className={styles.editIcon} />
          <Text
            tag="h2"
            size="s12"
            weight="wb"
            lineHeight="h13"
            color="000000"
            className={styles.editText}
          >
            Edit
          </Text>
        </div>
      )}

      {isEditMode ? (
        <EditMode user={user} handleCancel={() => setIsEditMode(false)} />
      ) : (
        <ViewMode user={user} />
      )}
    </WrapperBlock>
  );
};

Contacts.propTypes = {
  user: PropTypes.object.isRequired,
};

Contacts.defaultProps = {};

export default Contacts;
