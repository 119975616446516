import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import WrapperBlock from 'components/shared/WrapperBlock';
import LinkPDF from 'components/shared/LinkPDF';
import Text from 'components/shared/Text';
import Banner from 'components/shared/Banner';
import MobileContext from 'context/MobileContext';
import Note from 'components/shared/NoteText';
import Row from 'components/Projects/RowText';
import AppConfigContext from 'context/AppConfigContext';
import Select from 'components/shared/Select';
import Datepicker from 'components/shared/Datepicker';
import { formatDate } from 'utils/dateFormatters';
import styles from '../../style.module.css';
import stepStyles from './style.module.css';

const InstallationPreparationStep = ({
  materialDeliveryDate,
  satelliteDish,
  solarPanelsRemovalDate,
  installationDate,
  noteDescriptionFirstPart,
  description,
  handleChange,
  isDisabled,
  options,
  isEditSolarDate,
}) => {
  const isMobile = useContext(MobileContext);
  const appConfig = useContext(AppConfigContext);

  const documentLink = appConfig?.readBeforeInstallLink;

  return (
    <>
      <div className={styles.projectFormBanner}>
        <Banner isShow isHideCloseButton>
          <div>
            <Text color="#000" size="s16" weight="wb" tag="p">
              Important
            </Text>
            <br />
            <Text color="#000" size="s14">
              If you receive a check with your mortgage company listed as a payee, please
              contact us to further assist with the endorsement process.
            </Text>
          </div>
        </Banner>
      </div>
      <div>
        <WrapperBlock
          title="Installation Preparation"
          rightComponent={
            documentLink &&
            !isMobile && <LinkPDF href={documentLink}>Read before install</LinkPDF>
          }
        >
          <div className={stepStyles.formContent}>
            {documentLink && isMobile && (
              <div className={styles.rowContainerMargin}>
                <LinkPDF href={documentLink}>Read before install</LinkPDF>
              </div>
            )}

            {isEditSolarDate && (
              <div className={styles.rowContainerMargin}>
                <Row
                  name="Solar Panel Removal Date"
                  value={
                    isDisabled ? (
                      formatDate(solarPanelsRemovalDate)
                    ) : (
                      <div>
                        <Datepicker
                          name="solarPanelsRemovalDate"
                          value={solarPanelsRemovalDate}
                          handleChange={(date) => {
                            handleChange({
                              target: { value: date, name: 'solarPanelsRemovalDate' },
                            });
                          }}
                        />
                      </div>
                    )
                  }
                  isValueBold={isMobile}
                />
              </div>
            )}

            <div className={styles.rowContainerMargin}>
              <Row
                name="Satellite Dish Removal"
                isValueBold={isMobile && isDisabled}
                value={
                  isDisabled ? (
                    options.satelliteDishList.find((item) => item.value === satelliteDish)
                      ?.label
                  ) : (
                    <div className={stepStyles.selectWrapper}>
                      <Select
                        value={satelliteDish}
                        name="satelliteDish"
                        onChange={handleChange}
                        isDisabled={isDisabled}
                        options={options.satelliteDishList}
                      />
                    </div>
                  )
                }
              />
            </div>
          </div>
          <div className={styles.rowContainerMargin}>
            <Note isNoteDescription>
              Solar panels are required to be removed AT LEAST three (3) days prior to the
              scheduled roof installation date. These items may not be reinstalled until
              after the final roof inspection has passed. If you choose to detach only,
              please call your satellite provider to schedule a time to re-install and
              re-set the dish.
            </Note>
          </div>
          <br />
          {!isMobile && <br />}
          <div className={stepStyles.formContent}>
            <div className={styles.rowContainerMargin}>
              <Row
                name="Material Delivery Date"
                value={materialDeliveryDate || 'Coming soon'}
                isValueBold={isMobile}
              />
            </div>
            <div className={styles.rowContainerMargin}>
              <Row
                name="Installation Date"
                value={installationDate || 'Coming soon'}
                isValueBold
              />
            </div>
          </div>
          <Note isNoteDescription>
            {noteDescriptionFirstPart}
            <br />
            <br />
            {description}
          </Note>
        </WrapperBlock>
      </div>
    </>
  );
};

InstallationPreparationStep.propTypes = {
  materialDeliveryDate: PropTypes.string,
  noteDescriptionFirstPart: PropTypes.string,
  installationDate: PropTypes.string,
  description: PropTypes.string,
  solarPanelsRemovalDate: PropTypes.string,
  satelliteDish: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  options: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool,
  isEditSolarDate: PropTypes.bool.isRequired,
};

InstallationPreparationStep.defaultProps = {
  materialDeliveryDate: '',
  description: `*Rooftop delivery occurs in most locations`,
  noteDescriptionFirstPart: `Roofclaim.com and/or its materialmen shall be delivering
  materials to the rooftop of your home via semi-truck equipped with a conveyor system.* 
  The truck is required to pull onto your driveway so that the conveyor will reach the roof. To avoid delays, 
  ensure that your driveway is clear on the scheduled delivery date.`,
  solarPanelsRemovalDate: '',
  satelliteDish: '',
  installationDate: '',
  isDisabled: false,
};

export default InstallationPreparationStep;
