import React from 'react';
import Text from 'components/shared/Text';
import Icon from 'components/shared/IconSVG';
import { chatIcon, phoneIcon, contactIcon } from 'assets/icons';
import { handleOpenChat } from 'utils/handleOpenChat';
import supportImageMob from 'assets/backgrounds/img_support_mob.png';

import styles from './styles.module.css';

const ContactUs = () => {
  const buttonList = [
    {
      name: 'Call us',
      icon: phoneIcon,
      href: `tel:${process.env.REACT_APP_ROOFCALIM_PHONE}`,
    },
    {
      name: 'Send an email',
      icon: contactIcon,
      href: `mailto:${process.env.REACT_APP_ROOFCALIM_EMAIL}`,
    },
    {
      name: 'Chat with us',
      icon: chatIcon,
      href: '',
    },
  ];

  return (
    <div className={styles.container}>
      <Icon src={supportImageMob} className={styles.supportImage} />
      <div className={styles.textWrapper}>
        <Text
          tag="h2"
          size="s24"
          weight="wb"
          lineHeight="h26"
          className={styles.helpText}
        >
          Need help?
        </Text>

        <Text
          tag="p"
          size="s16"
          weight="wr"
          lineHeight="h21"
          color="#000000"
          className={styles.descriptionText}
        >
          If you have a question or issue navigating the website, please reach out to the
          RoofClaim.com support team via the options below.
        </Text>
      </div>
      <div className={styles.buttonsContainer}>
        {buttonList.map(({ name, icon, href }) => (
          <div
            className={
              name === 'Send an email'
                ? styles.buttonContainerCenter
                : styles.buttonContainer
            }
            key={name}
          >
            {name === 'Chat with us' ? (
              <div className={styles.buttonWrapper} onClick={() => handleOpenChat()}>
                <Icon src={icon} height="28px" width="28px" className={styles.icon} />
              </div>
            ) : (
              <a href={href} target="_blank" rel="noreferrer noopener">
                <div className={styles.buttonWrapper}>
                  <Icon src={icon} height="28px" width="28px" className={styles.icon} />
                </div>
              </a>
            )}
            <Text
              tag="p"
              size="s16"
              weight="wb"
              lineHeight="h16"
              color="#fff"
              className={styles.nameText}
            >
              {name}
            </Text>
          </div>
        ))}
      </div>
    </div>
  );
};

ContactUs.propTypes = {};

export default ContactUs;
