import React from 'react';
import Text from 'components/shared/Text';
import styles from '../../../style.module.css';

const SafetyInformationSection = () => {
  return (
    <div className={styles.rowContainerMargin}>
      <Text color="#000000" size="s14">
        This is a major construction project; as such, debris is likely to fall from the
        roof during the work. Until RoofClaim.com leaves the work site, we advise that you
        do not enter or exit the premises.
      </Text>{' '}
      <Text color="#000000" size="s13" weight="wb">
        If you must do so regardless, please adhere to the following instructions:
      </Text>
      <br />
      <br />
      <Text color="#000000" size="s14">
        - Get the attention of a crew member so that they may cease work and allow you to
        safely enter or exit the premises. <br />- Only walk on hard surfaces, as roofing
        debris may be hidden in landscaping, and secure all pets; do not allow them to
        roam the property.
      </Text>
    </div>
  );
};

SafetyInformationSection.propTypes = {};

SafetyInformationSection.defaultProps = {};

export default SafetyInformationSection;
