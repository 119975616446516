import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory, Link as ReactLink } from 'react-router-dom';
import Collapse from 'components/shared/Collapse';
import sortBy from 'lodash/sortBy';
import isEmpty from 'lodash/isEmpty';
import Banner from 'components/shared/Banner';
import NoteText from 'components/shared/NoteText';
import Link from 'components/shared/Link';
import MobileContext from 'context/MobileContext';
import useModal from 'services/hooks/useIsShowComponent';
import AcknowledgmentModal from 'components/shared/modals/AcknowledgmentModal';
import Frame from 'components/shared/Frame';
import ContactForm from './ContactForm';
import PropertyDetailsForm from './PropertyDetailsForm';
import InsuranceCompanyForm from './InsuranceCompanyForm';
import MortgageCompanyForm from './MortgageCompanyForm';
import ContractForm from './ContractForm';
import styles from '../../style.module.css';
import { CenterButton } from '../Footer';

const topValueErrors = {
  isGateCodeRequired: {
    top: '550',
    weight: 1,
  },
  gateCode: {
    top: '600',
    weight: 1,
  },
  existingShingleType: {
    top: '650',
    weight: 2,
  },
  isRoofTarped: {
    top: '700',
    weight: 3,
  },
  insuranceCompanyId: {
    top: '1000',
    weight: 4,
  },
  policyNumber: {
    top: '1000',
    weight: 5,
  },
  otherInsurance: {
    top: '1000',
    weight: 6,
  },
  claimNumber: {
    top: '1100',
    weight: 8,
  },
  isInsuranceApprovedClaim: {
    top: '1100',
    weight: 9,
  },
  isIncludeMortgageCompany: {
    top: '1400',
    weight: 10,
  },
  mortgageCompanyId: {
    top: '1500',
    weight: 11,
  },
  loanNumber: {
    top: '1500',
    weight: 12,
  },
};
function InsuranceClaimStep({
  values,
  errors,
  touched,
  handleChange = () => {},
  handleSubmit,
  options = {},
  contractProps,
  welcomeMessage,
  isRelatedContract,
  address,
  isLoadingOptions,
  isDisableForm,
  contacts,
  isNewProject,
  projectId,
  contractLink,
  bannerFrame,
  ...props
}) {
  const isMobile = useContext(MobileContext);
  const { isShow: isOpenModal, handleClick: handleSignContract } = useModal();
  const history = useHistory();

  useEffect(() => {
    if (history.action === 'REPLACE') handleSignContract();
  }, []);

  useEffect(() => {
    if (!isEmpty(errors) && !isEmpty(touched)) {
      const errorsTop = sortBy(
        Object.keys(errors).map((key) => topValueErrors[key]),
        'weight'
      );
      window.scrollTo({ top: errorsTop[0].top, behavior: 'smooth' });
    }
  }, [errors, touched]);

  return (
    <>
      {bannerFrame && (
        <div className={styles.projectFormBanner}>
          <Banner isShow handleClose={() => {}}>
            <>
              {welcomeMessage}
              {bannerFrame}
            </>
          </Banner>
        </div>
      )}
      {isMobile && (
        <div className={styles.projectFormBanner}>
          <NoteText isNoteDescription>
            You can modify your contact information or create a new contact under{' '}
            <ReactLink to="/my-account">
              <Link bold isUnderlined href="/my-account">
                My Account
              </Link>
            </ReactLink>
          </NoteText>
        </div>
      )}

      <div className={styles.projectForm}>
        <Collapse title="Contact" isOpen isBigContent>
          {contacts.map((contact, index) => (
            <div key={index}>
              <ContactForm
                values={contact}
                isDisabled={isDisableForm}
                isLoadingOptions={isLoadingOptions}
                options={options}
                isAvailableDelete
                isMobile={isMobile}
              />
              {contacts.length > 1 && index !== contacts.length - 1 && (
                <div className={styles.bottomLine} />
              )}
            </div>
          ))}
          {!isMobile && (
            <NoteText isNoteDescription>
              You can modify your contact information or create a new contact under{' '}
              <ReactLink to="/my-account">
                <Link bold isUnderlined href="/my-account">
                  My Account
                </Link>
              </ReactLink>
            </NoteText>
          )}
        </Collapse>
      </div>
      <div className={styles.projectForm}>
        <Collapse title="Property Details" isOpen>
          <PropertyDetailsForm
            values={values}
            options={options}
            isLoadingOptions={isLoadingOptions}
            isDisabled={isDisableForm}
            errors={errors}
            touched={touched}
            address={address}
            handleChange={handleChange}
          />
        </Collapse>
      </div>
      <div className={styles.projectForm}>
        <Collapse title="Insurance Company" isOpen>
          <InsuranceCompanyForm
            {...props}
            values={values}
            options={options}
            isLoadingOptions={isLoadingOptions}
            isDisabled={isDisableForm}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
          />
        </Collapse>
      </div>
      <div className={styles.projectForm}>
        <Collapse title="Mortgage Company" isOpen>
          <MortgageCompanyForm
            values={values}
            options={options}
            isLoadingOptions={isLoadingOptions}
            isDisabled={isDisableForm}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
          />
        </Collapse>
      </div>
      {!isNewProject && projectId && (
        <div className={styles.projectForm}>
          <ContractForm
            {...contractProps}
            contractLink={contractLink}
            handleSignContract={handleSignContract}
          />
        </div>
      )}
      {isNewProject && !isDisableForm && (
        <CenterButton size="large" handleClick={handleSubmit}>
          Generate My Contract
        </CenterButton>
      )}

      <AcknowledgmentModal
        handleClose={() => {
          handleSignContract();
        }}
        footer={<Frame link={contractLink} type />}
        title="Please sign your RoofClaim.com contract"
        isShow={isOpenModal}
        isContract
      />
    </>
  );
}

InsuranceClaimStep.propTypes = {
  values: PropTypes.object.isRequired,
  errors: PropTypes.object,
  touched: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  options: PropTypes.object,
  contractProps: PropTypes.object,
  welcomeMessage: PropTypes.node,
  isRelatedContract: PropTypes.bool.isRequired,
  address: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  isLoadingOptions: PropTypes.bool,
  isDisableForm: PropTypes.bool,
  contacts: PropTypes.array.isRequired,
  isNewProject: PropTypes.bool.isRequired,
  projectId: PropTypes.string,
  contractLink: PropTypes.string.isRequired,
  bannerFrame: PropTypes.node,
};

InsuranceClaimStep.defaultProps = {
  errors: {
    contacts: [],
  },
  touched: {
    contacts: [],
  },
  options: {},
  contractProps: {},
  welcomeMessage: null,
  address: '',
  projectId: '',
  isLoadingOptions: false,
  isDisableForm: false,
  bannerFrame: null,
};

export default InsuranceClaimStep;
