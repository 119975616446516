import React from 'react';
import PropTypes from 'prop-types';
import Text from 'components/shared/Text';
import Icon from 'components/shared/IconSVG';
import { chatIcon, supportIcon, phoneIcon, contactIcon } from 'assets/icons';
import supportImage from 'assets/backgrounds/img_support.png';
import Button from 'components/shared/Button';
import { handleOpenChat } from 'utils/handleOpenChat';
import styles from './styles.module.css';

const ContactUs = ({ contactUsPage, titleText, bodyText }) => {
  return (
    <div className={styles.container}>
      <Icon
        src={supportIcon}
        height="220px"
        width="200px"
        className={styles.supportIcon}
      />
      <div className={!contactUsPage ? styles.textWrapper : styles.textWrapperContactUs}>
        <Text
          tag="h2"
          size="s28"
          weight="wb"
          lineHeight="h35"
          className={styles.helpText}
        >
          {titleText}
        </Text>

        <Text
          tag="p"
          size="s19"
          weight="wr"
          lineHeight="h25"
          color="#000000"
          className={
            !contactUsPage ? styles.descriptionText : styles.descriptionTextContactUs
          }
        >
          {bodyText}
        </Text>

        <div
          className={
            !contactUsPage ? styles.buttonContainer : styles.buttonContainerContactUs
          }
        >
          <div className={styles.callButtonContainer}>
            <a href={`tel:${process.env.REACT_APP_ROOFCALIM_PHONE}`}>
              <Button size="smaller" color="secondary" className={styles.callButton}>
                Call us
              </Button>
            </a>

            <Icon
              src={phoneIcon}
              height="18px"
              width="18px"
              className={styles.phoneIcon}
            />
          </div>

          {!contactUsPage ? (
            <div className={styles.emailButtonContainer}>
              <a
                href={`mailto:${process.env.REACT_APP_ROOFCALIM_EMAIL}`}
                target="_blank"
                rel="noreferrer noopener"
              >
                <Button size="medium" color="secondary" className={styles.emailButton}>
                  Send an email
                </Button>
              </a>
              <Icon
                src={contactIcon}
                height="18px"
                width="18px"
                className={styles.emailIcon}
              />
            </div>
          ) : null}
          <div className={styles.chatButtonContainer}>
            <Button
              size="medium"
              color="secondary"
              className={!contactUsPage ? styles.callButton : styles.callButtonContactUs}
              handleClick={handleOpenChat}
            >
              Chat with us
            </Button>
            <Icon
              src={chatIcon}
              height="18px"
              width="18px"
              className={!contactUsPage ? styles.chatIcon : styles.chatIconContactUs}
            />
          </div>
        </div>
      </div>

      {!contactUsPage ? (
        <Icon src={supportImage} height="300px" className={styles.supportImage} />
      ) : null}
    </div>
  );
};

ContactUs.propTypes = {
  contactUsPage: PropTypes.bool,
  titleText: PropTypes.string.isRequired,
  bodyText: PropTypes.string.isRequired,
};
ContactUs.defaultProps = {
  contactUsPage: false,
};

export default ContactUs;
