import React from 'react';
import WrapperBlock from 'components/shared/WrapperBlock';
import DownloadPDFLink from 'components/shared/LinkPDF';
import { useQuery } from '@apollo/react-hooks';
import { DOWNLOAD_FILES_LINK } from 'graphql/queries/file';
import Spinner from 'components/shared/Spinner';
import Text from 'components/shared/Text';
import styles from '../../style.module.css';
import documentStyles from '../style.module.css';

function PowerForms() {
  const { data, loading } = useQuery(DOWNLOAD_FILES_LINK);
  const attachments = [
    { link: data?.downloadFilesLink[0]?.insurancePolicyLink, name: 'Insurance Policy' },
    {
      link: data?.downloadFilesLink[0]?.warrantyPolicyLink,
      name: 'Example Warranty Policy',
    },
  ];
  return (
    <div className={styles.projectForm}>
      <WrapperBlock title="Power Forms & Templates">
        {loading ? <Spinner /> : null}
        {!loading ? (
          <div className={documentStyles.documentFormContainer}>
            {!attachments[0].link && !attachments[1].link ? (
              <Text
                color="#000"
                weight="wr"
                size="s16"
                tag="h2"
                lineHeight="h18"
                className={documentStyles.title}
              >
                No files yet
              </Text>
            ) : null}
            {attachments.map(({ link, name }, templateIndex) => {
              if (!link) return null;
              return (
                <div className={documentStyles.documentWrapper} key={templateIndex}>
                  <DownloadPDFLink href={link}>{name}</DownloadPDFLink>
                </div>
              );
            })}
          </div>
        ) : null}
      </WrapperBlock>
    </div>
  );
}

PowerForms.propTypes = {};

PowerForms.defaultProps = {};

export default PowerForms;
