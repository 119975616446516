import React from 'react';
import SignInForm from 'containers/SignInContainer';
import styles from './style.module.css';
import MobileSignedOutHeader from '../../layouts/Mobile/MobileSignedOutHeader';
import WelcomeSplashScreen from 'components/Welcome-Splash-Screen/Welcome-Splash-Screen';


const SignInPage = () => {
  return (
    <MobileSignedOutHeader>
      <WelcomeSplashScreen />
      <main>
        <div className={styles.contentPage}>
          <SignInForm />
        </div>
      </main>
    </MobileSignedOutHeader>
  );
};

export default SignInPage;
