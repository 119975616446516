import React from 'react';
import PropTypes from 'prop-types';
import ServiceDoc from 'components/Projects/Documents/MyDocuments/ServiceDoc';
import useLoadAttachments from 'services/hooks/loadAttachments';

const ServiceDocContainer = ({ pgId }) => {
  const { data, loading } = useLoadAttachments({
    opportunityId: pgId,
    documentCategory: 'Service',
  });

  return <ServiceDoc files={data?.downloadFilesName} load={loading} />;
};

ServiceDocContainer.propTypes = {
  pgId: PropTypes.string.isRequired,
};

export default ServiceDocContainer;
