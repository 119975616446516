import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import useModal from 'services/hooks/useIsShowComponent';
import MobileContext from 'context/MobileContext';
import RoofProject from 'components/Projects/RoofProjects';
import ProjectDetails from 'components/Projects/ProjectDetails';
import Documents from 'components/Projects/Documents';
import Messages from 'components/Projects/Messages';
import Tabs from 'components/shared/Tabs/Desktop';
import MobileTabs from 'components/shared/Tabs/Mobile';
import getStageRoofProject from 'utils/stageRoofProject';
import LayoutWithSidebar from 'layouts/LayoutWithSidebar';
import { getRoofProjects } from 'graphql/cache/project';

const RoofProjectPage = ({ project = {}, isNewProject, ...props }) => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [isNavigationBlocked, setIsNavigationBlocked] = useState(false);
  const [path, setPath] = useState('');
  const isMobile = useContext(MobileContext);
  const data = project || {};

  let currentDraftProject = {};

  if (getRoofProjects().length)
    currentDraftProject = {
      ...getRoofProjects().filter(({ stageName }) => stageName === 'Draft')[0],
    };

  const {
    isShow: isOpenConfirmChangeModal,
    handleClick: handleClickConfirmChangeModal,
  } = useModal(false);

  const handleOpenDocuments = () => {
    setCurrentTabIndex(2);
  };

  const projectDetails = {
    idCounter: data.idCounter,
    gateCode: data.gateCode,
    address: data.address,
    contacts: data.contacts,
    insuranceCompany: data.otherInsurance || data.insuranceCompany?.name,
    policyNumber: data.policyNumber,
    claimNumber: data.claimNumber,
    contractor: data.contractor,
    dateOfInstall: data.dateOfInstall,
  };

  const tabList = [
    {
      title: 'Roof Project',
      children: (
        <RoofProject
          {...props}
          projectData={data}
          handleOpenDocuments={handleOpenDocuments}
          isNewProject={isNewProject}
          currentStage={getStageRoofProject(data, isNewProject)}
          setIsNavigationBlocked={setIsNavigationBlocked}
          isOpenConfirmChangeModal={isOpenConfirmChangeModal}
          handleClickConfirmChangeModal={handleClickConfirmChangeModal}
          setCurrentTabIndex={setCurrentTabIndex}
          path={path}
          setPath={setPath}
        />
      ),
    },
    {
      title: 'Project Details',
      children: <ProjectDetails {...projectDetails} />,
    },
    {
      title: 'Documents',
      children: <Documents project={project} />,
    },
    { title: 'Messages', children: <Messages projectId={project.sfId} /> },
  ];

  return (
    <LayoutWithSidebar>
      {isMobile ? (
        <MobileTabs
          tabList={tabList}
          currentTabIndex={currentTabIndex}
          projectPgId={currentDraftProject?.pgId}
          handleClick={
            isNavigationBlocked
              ? (tabIndex) => {
                  setPath(`index:${tabIndex}`);
                  handleClickConfirmChangeModal();
                }
              : setCurrentTabIndex
          }
        />
      ) : (
        <Tabs
          tabList={tabList}
          currentTabIndex={currentTabIndex}
          projectPgId={currentDraftProject?.pgId}
          handleClick={
            isNavigationBlocked
              ? (tabIndex) => {
                  setPath(`index:${tabIndex}`);
                  handleClickConfirmChangeModal();
                }
              : setCurrentTabIndex
          }
        />
      )}
    </LayoutWithSidebar>
  );
};

RoofProjectPage.propTypes = {
  project: PropTypes.object,
  isNewProject: PropTypes.bool,
};

RoofProjectPage.defaultProps = {
  project: {},
  isNewProject: false,
};

export default RoofProjectPage;
