import React from 'react';
import PropTypes from 'prop-types';
import {
  fbIcon,
  instagramIcon,
  linkedinIcon,
  twitterIcon,
  youtubeIcon,
  chevronIcon,
  appStoreIcon,
  playStoreIcon,
} from 'assets/icons';

import logoRoof from 'assets/logos/logo_roofclaim.png';
import Icon from 'components/shared/IconSVG';
import Text from 'components/shared/Text';
import { useQuery } from '@apollo/react-hooks';
import { GET_USER } from 'graphql/queries/auth';
import styles from './style.module.css';

const Footer = ({ loading }) => {
  const { data } = useQuery(GET_USER);

  const usefulLinks = [
    { name: 'FAQs', url: '/faq' },
    { name: 'About Us', url: 'https://www.roofclaim.com/about-us/' },
    { name: 'Contact Us', url: '/contact-us' },
    { name: 'Referral Rewards', url: 'https://www.roofclaim.com/referral-program/' },
  ];

  const mediaLinks = [
    {
      icon: fbIcon,
      url: 'https://www.facebook.com/RoofClaimUS/',
    },
    {
      icon: twitterIcon,
      url: 'https://twitter.com/RoofclaimUS',
    },
    {
      icon: youtubeIcon,
      url: 'https://www.youtube.com/channel/UCbIWrNbRjbWFm0GFNN2f01Q',
    },
    {
      icon: linkedinIcon,
      url: 'https://www.linkedin.com/company/roofclaim-com/',
    },
    {
      icon: instagramIcon,
      url: 'https://www.instagram.com/roofclaimus/',
    },
  ];
  const googlePlayUrl = process.env.REACT_APP_GOOGLE_PLAY_APP_URL;
  const appStoreUrl = process.env.REACT_APP_APP_STORE_URL;

  return (
    <div className={styles.container}>
      {/* {!loading && <Chat user={data?.getUser} />} */}
      <Icon src={logoRoof} height="25px" width="223px" className={styles.logo} />
      <div
        onClick={() =>
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          })
        }
        className={styles.anchor}
      >
        <Icon
          src={chevronIcon}
          height="20px"
          width="20px"
          className={styles.anchorIcon}
        />
      </div>
      <div className={styles.linksContainer}>
        {(appStoreUrl || googlePlayUrl) && (
          <div className={styles.storeButtonContainer}>
            <Text tag="h2" size="s14" weight="wb" lineHeight="h16" color="#FFFFFF">
              Download our app!
            </Text>
            <div className={styles.buttonsGrid}>
              {appStoreUrl && (
                <a
                  className={styles.appStoreLink}
                  href={appStoreUrl}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <Icon
                    src={appStoreIcon}
                    height="40px"
                    width="135px"
                    className={styles.appStoreIcon}
                  />
                </a>
              )}

              {googlePlayUrl && (
                <a
                  className={styles.appStoreLink}
                  href={googlePlayUrl}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <Icon
                    src={playStoreIcon}
                    height="40px"
                    width="135px"
                    className={styles.playStoreIcon}
                  />
                </a>
              )}
            </div>
          </div>
        )}

        <div className={styles.usefulLinksContainer}>
          <Text
            tag="h2"
            size="s14"
            weight="wb"
            lineHeight="h16"
            color="#FFFFFF"
            className={styles.linksTitle}
          >
            Useful Links
          </Text>
          {usefulLinks.map(({ url, name }) => (
            <a
              href={url}
              key={name}
              target={name === 'Contact Us' ? '' : '_blank'}
              rel="noreferrer noopener"
            >
              <Text
                tag="h2"
                size="s14"
                weight="wr"
                lineHeight="h16"
                color="#FFFFFF"
                className={styles.usefulLink}
              >
                {name}
              </Text>
            </a>
          ))}
        </div>

        <div className={styles.mediaLinksContainer}>
          <Text
            tag="h2"
            size="s14"
            weight="wb"
            lineHeight="h16"
            color="#FFFFFF"
            className={styles.linksTitle}
          >
            Follow Us
          </Text>
          <div className={styles.mediaIconContainer}>
            {mediaLinks.map(({ url, icon }) => (
              <a href={url} key={url} target="_blank" rel="noreferrer noopener">
                <Icon
                  src={icon}
                  height="20px"
                  width="20px"
                  className={styles.mediaLink}
                />
              </a>
            ))}
          </div>
        </div>
      </div>
      <Text
        tag="h2"
        size="s16"
        weight="wr"
        lineHeight="h21"
        color="#837F7D"
        className={styles.bottomText}
      >
        <a
          className={styles.privacyLink}
          href="https://www.roofclaim.com/privacy-policy/"
          target="_blank"
          rel="noreferrer noopener"
        >
          <span> </span>
          Privacy Policy
        </a>
        <span> </span>|<span> </span>
        <a
          className={styles.privacyLink}
          href="https://www.roofclaim.com/terms-and-conditions/"
          target="_blank"
          rel="noreferrer noopener"
        >
          Terms and Conditions
        </a>
      </Text>
      <Text
        tag="h2"
        size="s16"
        weight="wr"
        lineHeight="h21"
        color="#837F7D"
        className={styles.bottomTextMobile}
      >
        <a
          className={styles.privacyLink}
          href="https://www.roofclaim.com/privacy-policy/"
          target="_blank"
          rel="noreferrer noopener"
        >
          {' '}
          Privacy Policy
        </a>{' '}
        |{' '}
        <a
          className={styles.privacyLink}
          href="https://www.roofclaim.com/terms-and-conditions/"
          target="_blank"
          rel="noreferrer noopener"
        >
          Terms and Conditions
        </a>
      </Text>
    </div>
  );
};

Footer.propTypes = {
  loading: PropTypes.bool,
};

Footer.defaultProps = {
  loading: false,
};

export default Footer;
