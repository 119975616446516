import React from 'react';
import PropTypes from 'prop-types';
import Text from 'components/shared/Text';
import { useQuery } from '@apollo/react-hooks';
import { CHAT_TRANSCRIPTS } from 'graphql/queries/chatTranscripts';
import Spinner from 'components/shared/Spinner';
import { formatDateTime } from 'utils/dateFormatters';
import styles from './style.module.css';

const ChatTranscripts = ({ user }) => {
  const { loading, data } = useQuery(CHAT_TRANSCRIPTS, {
    variables: { contactId: user.sfId },
  });
  const filteredChatTrascriptins = data?.chatTranscripts.filter(
    (item) => item.status === 'Completed'
  );
  if (loading)
    return (
      <div className={styles.tabContentSpinner}>
        <Spinner />
      </div>
    );

  if (!filteredChatTrascriptins?.length)
    return (
      <div className={styles.tabContentEmpty}>
        <Text color="#000" size="s16">
          You don't have any messages
        </Text>
      </div>
    );

  return (
    <div className={styles.tabContent}>
      {filteredChatTrascriptins.map(({ title, body, createdDate }, sectionIndex) => {
        return (
          <div className={styles.messageWrapper} key={sectionIndex}>
            <div className={styles.messageTitle}>
              {/* <Text color="#000000" weight="wb" size="s16">
                {type}
              </Text> */}
              <Text color="#000000" weight="wb" size="s14">
                {formatDateTime(createdDate)}
              </Text>
              <div className={styles.wrapperBlockLine} />
            </div>
            <div>
              {/* {title && (
                <Text color="#000000" weight="wb" size="s14" tag="p">
                  Title: {title}
                </Text>
              )} */}
              {body && (
                <Text color="#000000" weight="wr" size="s14" tag="p" lineHeight="h18">
                  <span dangerouslySetInnerHTML={{ __html: body }} />
                </Text>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};
ChatTranscripts.propTypes = {
  user: PropTypes.object.isRequired,
};

export default ChatTranscripts;
