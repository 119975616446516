import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import WrapperBlock from 'components/shared/WrapperBlock';
import LinkPDF from 'components/shared/LinkPDF';
import Text from 'components/shared/Text';
import Link from 'components/shared/Link';
import {
  CenterButton as FooterButtonMob,
  Footer as Buttons,
} from 'components/Projects/RoofProjects/Footer';
import EmailLink from 'components/shared/Link/EmailLink';
import PhoneLink from 'components/shared/Link/PhoneLink';
import phoneNumberFormatter from 'utils/phoneNumberFormatter';
import Row from 'components/Projects/RowText';
import isCustomContractor from 'utils/checkIsCustomContractor';

import MobileContext from 'context/MobileContext';
import { useQuery } from '@apollo/react-hooks';
import AppConfigContext from 'context/AppConfigContext';
import { GET_WARRANTIES } from 'graphql/queries/warranty';
import Spinner from 'components/shared/Spinner';
import styles from '../../style.module.css';

const WarrantyStep = ({
  expirationDate,
  handleClose,
  contractor,
  handleOpenDocuments,
  materialWarranty,
  opportunityPgId,
}) => {
  const { loading, data } = useQuery(GET_WARRANTIES);

  const isWarrantyAlreadyOpen = !!data?.getWarranties?.filter(
    (warranty) => warranty.opportunityPgId === opportunityPgId
  ).length;

  const isMobile = useContext(MobileContext);
  const appConfig = useContext(AppConfigContext);

  const history = useHistory();

  const warrantyPDFLink = appConfig?.warrantyPolicyLink;
  if (loading) return <Spinner />;
  return (
    <>
      <div>
        <WrapperBlock
          title="Warranty"
          rightComponent={
            warrantyPDFLink &&
            !isMobile && <LinkPDF id="btn-warranty-policy-pdf" href={warrantyPDFLink}>Example Warranty Policy</LinkPDF>
          }
          isForm
        >
          <div className={styles.rowContainerMargin}>
            <Row
              name="Warranty Expiration Date"
              value={expirationDate || 'Date is not yet set'}
              isValueBold
            />
          </div>
          {isCustomContractor(contractor) && (
            <>
              <div className={styles.rowContainerMargin}>
                <Row
                  name="Contractor Name"
                  value={`${contractor.firstName} ${contractor.lastName}`}
                  isValueBold={isMobile}
                />
              </div>

              <div className={styles.rowContainerMargin}>
                <Row
                  value={
                    <div className={styles.contractSection}>
                      <div
                        className={[
                          styles.contractSectionItem,
                          styles.contractSectionItemFirst,
                        ].join(' ')}
                      >
                        <EmailLink mailTo={contractor?.email} isBold={isMobile} />
                      </div>
                      <div
                        className={[
                          styles.contractSectionItem,
                          styles.contractSectionItemLast,
                        ].join(' ')}
                      >
                        <PhoneLink
                          phoneNumber={phoneNumberFormatter(contractor?.phone)}
                          isBold={isMobile}
                        />
                      </div>
                    </div>
                  }
                />
              </div>
            </>
          )}

          <div className={styles.rowContainerMargin}>
            <Row name="Material Warranty by" value="Not set yet" isValueBold={isMobile} />
          </div>
          <br />
          <div className={styles.rowContainerMargin}>
            <Text color="#686868" size="s14">
              Please see your warranty documentation under{' '}
              <span onClick={handleOpenDocuments} className={styles.textLink}>
                DOCUMENTS
              </span>{' '}
              for details.
            </Text>
          </div>

          {warrantyPDFLink && isMobile && (
            <>
              <br />
              <LinkPDF id="btn-warranty-policy-pdf" href={warrantyPDFLink}>Example Warranty Policy</LinkPDF>
            </>
          )}
        </WrapperBlock>
      </div>
      <Buttons handleCancel={handleClose} isHideNext isHideSubmit />

      {isMobile && !isWarrantyAlreadyOpen && (
        <FooterButtonMob bottom handleClick={() => history.push('/warranty/new-claim')}>
          Open a warranty case
        </FooterButtonMob>
      )}
    </>
  );
};

WarrantyStep.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleOpenDocuments: PropTypes.func.isRequired,
  expirationDate: PropTypes.string,
  contractor: PropTypes.object,
  materialWarranty: PropTypes.string,
  opportunityPgId: PropTypes.string.isRequired,
};

WarrantyStep.defaultProps = {
  expirationDate: '',
  contractor: null,
  materialWarranty: '',
};

export default WarrantyStep;
