import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import TabletContext from 'context/TabletContext';
import { homeIcon, projectIcon, warrantyIcon, contactIcon, faqIcon } from 'assets/icons';
import WarrantyAvailabilityContext from 'context/WarrantyAvailabilityContext';
import Text from 'components/shared/Text';
import SidebarItem from './SidebarItem';
import styles from './style.module.css';

const firstPartMenu = [
  { name: 'Homepage', icon: homeIcon, path: '/' },
  {
    name: 'My Projects',
    icon: projectIcon,
    path: '/my-projects',
    isExact: false,
  },
  {
    name: 'My Referrals',
    icon: projectIcon,
    path: '/my-referrals',
    isExact: false,
  },
  { name: 'Warranty', icon: warrantyIcon, path: '/warranty', isExact: false },
];

const thirdPartMenu = [
  { name: 'Contact Us', icon: contactIcon, path: '/contact-us' },
  { name: 'FAQ', icon: faqIcon, path: '/faq' },
];

function Sidebar({ showSidebar, setShowSidebar }) {
  const isTablet = useContext(TabletContext);
  const isWarrantyAvailable = useContext(WarrantyAvailabilityContext);

  return (
    <div
      className={
        showSidebar
          ? styles.containerSidebarMobile
          : isTablet
            ? styles.containerSidebarHidenMobile
            : styles.containerSidebar
      }
    >
      {firstPartMenu.map(({ name, icon, path, isExact }) => {
        if (name === 'Warranty' && !isWarrantyAvailable) {
          return null;
        }
        return (
          <SidebarItem
            key={name}
            name={name}
            icon={icon}
            path={path}
            isExact={isExact}
            setShowSidebar={setShowSidebar}
          />
        );
      })}
      <div className={styles.separationLine} />

      {thirdPartMenu.map(({ name, icon, path, isExact }) => (
        <SidebarItem
          key={name}
          name={name}
          icon={icon}
          path={path}
          isExact={isExact}
          setShowSidebar={setShowSidebar}
        />
      ))}
      <div className={styles.separationLine} />
      <div className={styles.contactInfoContainer}>
        <Text color="#837f7d" weight="wr" size="s16" lineHeight="h18">
          <b>
            <a
              href={`tel:${process.env.REACT_APP_ROOFCALIM_PHONE}`}
              style={{ color: '#837f7d' }}
            >
              {process.env.REACT_APP_ROOFCALIM_PHONE}
            </a>{' '}
          </b>
          <br />
          <b>
            <a
              href={`mailto:${process.env.REACT_APP_ROOFCALIM_EMAIL}`}
              target="_blank"
              rel="noreferrer noopener"
              style={{ color: '#837f7d' }}
            >
              {process.env.REACT_APP_ROOFCALIM_EMAIL}
            </a>
          </b>
        </Text>
      </div>
    </div>
  );
}

Sidebar.propTypes = {
  showSidebar: PropTypes.bool.isRequired,
  setShowSidebar: PropTypes.func.isRequired,
};

Sidebar.defaultProps = {};

export default Sidebar;
