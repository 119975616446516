import React from 'react';
import PropTypes from 'prop-types';
import WrapperBlock from 'components/shared/WrapperBlock';
import CaseDoc from 'containers/RoofProject/Documents/CaseDocContainer';
import ServiceDoc from 'containers/RoofProject/Documents/ServiceDoc';
import OpportunityDoc from 'containers/RoofProject/Documents/OpportunityDocContainer';
import styles from '../../style.module.css';

function MyDocuments({ project }) {
  return (
    <div className={styles.projectForm}>
      <WrapperBlock title="My Documents">
        <OpportunityDoc sfId={project?.sfId} />
        <ServiceDoc pgId={project?.pgId} />
        <CaseDoc pgId={project?.pgId} />
      </WrapperBlock>
    </div>
  );
}

MyDocuments.propTypes = {
  project: PropTypes.object.isRequired,
};

MyDocuments.defaultProps = {};

export default MyDocuments;
